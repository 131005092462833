import { graphql } from 'gatsby';
import React from 'react';
import { mapToPageModel } from '../mappers/page.mapper';
import { Page } from '../models';
import { Helmet } from 'react-helmet';
import favicon from '../images/favicon.ico';

const Links = ({ data: nodePage }) => {
  const pageData: Page = mapToPageModel(nodePage.nodePage);

  return (
    <section className="vl-region">
      <Helmet>
        <title>Links | Barometer samenleven</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <div className="vl-layout">
        <h1 className="vl-title vl-title--h2">{pageData.title}</h1>
        <p
          className="vl-u-spacer-bottom  vl-typography"
          dangerouslySetInnerHTML={{ __html: pageData.body }}
        />
      </div>
    </section>
  );
};

export default Links;

export const query = graphql`
  query getNodePageLinks {
    nodePage(id: { eq: "e602ac71-df1c-5421-a16a-93baa736d0d8" }) {
      body {
        processed
      }
      title
      id
    }
  }
`;
